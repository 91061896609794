import { ConnectedSubscriptionSystemType } from '@pattern-library/src/utils/typescript-utils'
import type { Session } from 'next-auth'

import { PlanAdSettings, PropaySubscription, PropaySubscriptionPlan, StripePlan, SubscriptionPlan } from '@models/types'
import { DEFAULT_PLAN_AD_SETTINGS } from '@shared/constants'

import { AuthenticationStatusType } from './typescript-utils'

export const getUserSubscriptionPlan = ({
  session,
  stripePlans,
  stripeSupportPlans,
  propayPlans,
  connectedSubscriptionSystem,
}: {
  session: Session | undefined | null
  stripePlans: SubscriptionPlan[]
  stripeSupportPlans: SubscriptionPlan[]
  propayPlans: PropaySubscriptionPlan[]
  connectedSubscriptionSystem: ConnectedSubscriptionSystemType
}):
  | {
      system: 'PROPAY'
      type: 'publication'
      subscription: PropaySubscriptionPlan | undefined
    }
  | {
      system: 'STRIPE'
      type: 'support' | 'publication'
      subscription: SubscriptionPlan | undefined
    }
  | undefined => {
  // User has no plans.
  if (!session?.user?.stripeSubscriptions) return undefined
  if (connectedSubscriptionSystem === 'STRIPE') {
    const userSubscriptions = session?.user?.stripeSubscriptions

    // Find if any of the user's plans correspond to a available plan of the publication.
    const userSubscriptionPlan = stripePlans.find(subscriptionPlan =>
      userSubscriptions.find(
        userSubcription => subscriptionPlan.productId === userSubcription.items.data[0].plan.product,
      ),
    )
    if (userSubscriptionPlan) return { type: 'publication', subscription: userSubscriptionPlan, system: 'STRIPE' }

    // Find if any of the user's plans correspond to a support plan.
    const userSupportPlan = stripeSupportPlans?.find(plan =>
      userSubscriptions.find(userSubscription => plan.productId === userSubscription.items.data[0].plan.product),
    )
    if (userSupportPlan) return { type: 'support', subscription: userSupportPlan, system: 'STRIPE' }
  } else if (connectedSubscriptionSystem === 'PROPAY') {
    const userSubscriptionPlan = session?.user?.propaySubscriptions?.[0]
    const propayPlan = propayPlans.find(plan => plan.id === userSubscriptionPlan?.planId)
    if (propayPlan) return { type: 'publication', subscription: propayPlan, system: 'PROPAY' }
  }

  return undefined
}

export const getUserSubscription = ({
  session,
  connectedSubscriptionSystem,
}: {
  session: Session | undefined | null
  connectedSubscriptionSystem: ConnectedSubscriptionSystemType
}):
  | {
      system: 'PROPAY'
      subscription: PropaySubscription
    }
  | {
      system: 'STRIPE'
      subscription: StripePlan
    }
  | undefined => {
  if (connectedSubscriptionSystem === 'STRIPE') {
    const userSubscription = session?.user?.stripeSubscriptions?.[0]?.items.data[0].plan
    if (userSubscription) return { subscription: userSubscription, system: 'STRIPE' }
  } else if (connectedSubscriptionSystem === 'PROPAY') {
    const userSubscription = session?.user?.propaySubscriptions?.[0]
    if (userSubscription) return { subscription: userSubscription, system: 'PROPAY' }
  }
  return undefined
}

const getPublicationAvailableStripeSubscriptions = ({
  stripePlans,
  stripeSupportPlans,
}: {
  stripePlans: SubscriptionPlan[]
  stripeSupportPlans: SubscriptionPlan[]
}): string[] =>
  [
    stripePlans.map(plan => plan.stripePriceIdMonthly),
    stripePlans.map(plan => plan.stripePriceIdYearly),
    stripeSupportPlans.map(plan => plan.stripePriceIdMonthly),
    stripeSupportPlans.map(plan => plan.stripePriceIdYearly),
    ...stripePlans.map(plan => plan.previousPriceIds),
  ].flat()

export const isUserSubscribedToPublication = (props: {
  status: AuthenticationStatusType
  session: Session | null | undefined
  stripePlans: SubscriptionPlan[]
  stripeSupportPlans: SubscriptionPlan[]
  connectedSubscriptionSystem: ConnectedSubscriptionSystemType
}): boolean => !!getSubscriptionIdForPublication(props)

export const getSubscriptionIdForPublication = ({
  status,
  session,
  stripePlans,
  stripeSupportPlans,
  connectedSubscriptionSystem,
}: {
  status: AuthenticationStatusType
  session: Session | null | undefined
  stripePlans: SubscriptionPlan[]
  stripeSupportPlans: SubscriptionPlan[]
  connectedSubscriptionSystem: ConnectedSubscriptionSystemType
}): string | undefined => {
  if (status !== 'loading') {
    if (connectedSubscriptionSystem === 'STRIPE') {
      const publicationAvailableStripeSubscriptions = getPublicationAvailableStripeSubscriptions({
        stripePlans,
        stripeSupportPlans,
      })
      const stripeSubscription = session?.user?.stripeSubscriptions?.find(subscription => {
        const priceId = subscription.items.data[0].plan.id
        return publicationAvailableStripeSubscriptions.includes(priceId)
      })
      return stripeSubscription?.id
    } else if (connectedSubscriptionSystem === 'PROPAY') {
      return session?.user?.propaySubscriptions?.[0]?.id
    }
  }
}

export type SUBSCRIBED_USER_PLAN_RECCURENCE_TYPE = 'yearly' | 'weekly' | '28days' | 'monthly'

export const getSubscriptionRecurrence = ({
  status,
  session,
  stripePlans,
  stripeSupportPlans,
  connectedSubscriptionSystem,
}: {
  status: AuthenticationStatusType
  session: Session | undefined | null
  stripePlans: SubscriptionPlan[]
  stripeSupportPlans: SubscriptionPlan[]
  connectedSubscriptionSystem: ConnectedSubscriptionSystemType
}): SUBSCRIBED_USER_PLAN_RECCURENCE_TYPE | undefined => {
  if (status !== 'loading') {
    if (connectedSubscriptionSystem === 'STRIPE') {
      for (const subscription of session?.user?.stripeSubscriptions || []) {
        const priceId = subscription.items.data[0].plan.id
        for (const plan of stripePlans) {
          if (priceId === plan.stripePriceIdMonthly) {
            return 'monthly'
          }
          if (priceId === plan.stripePriceIdYearly) {
            return 'yearly'
          }
        }
        for (const supportPlan of stripeSupportPlans) {
          if (priceId === supportPlan.stripePriceIdMonthly) {
            return 'monthly'
          }
          if (priceId === supportPlan.stripePriceIdYearly) {
            return 'yearly'
          }
        }
      }
    } else if (connectedSubscriptionSystem === 'PROPAY') {
      switch (session?.user?.propaySubscriptions?.[0]?.paymentFrequency) {
        case 'MONTH':
          return 'monthly'
        case 'YEAR':
          return 'yearly'
        case 'TWENTY_EIGHT_DAYS':
          return '28days'
        default:
          return undefined
      }
    }
  }
  return undefined
}

export const adSystemOfSubscription = (props: {
  status: AuthenticationStatusType
  session: Session | null | undefined
  stripePlans: SubscriptionPlan[]
  stripeSupportPlans: SubscriptionPlan[]
  connectedSubscriptionSystem: ConnectedSubscriptionSystemType
}): PlanAdSettings => {
  if (props.connectedSubscriptionSystem === 'STRIPE') {
    for (const subscription of props.session?.user?.stripeSubscriptions || []) {
      const priceId = subscription.items.data[0].plan.id
      for (const plan of props.stripePlans) {
        if (
          priceId === plan.stripePriceIdMonthly ||
          priceId === plan.stripePriceIdYearly ||
          plan.previousPriceIds.includes(priceId)
        ) {
          return plan.adSettings || DEFAULT_PLAN_AD_SETTINGS
        }
      }
      for (const supportPlan of props.stripeSupportPlans) {
        if (
          priceId === supportPlan.stripePriceIdMonthly ||
          priceId === supportPlan.stripePriceIdYearly ||
          supportPlan.previousPriceIds.includes(priceId)
        ) {
          return supportPlan.adSettings || DEFAULT_PLAN_AD_SETTINGS
        }
      }
    }
  }
  return DEFAULT_PLAN_AD_SETTINGS
}

export const arePublicationsAvailable = ({
  stripePlans,
  stripeSupportPlans,
  propayPlans,
  connectedSubscriptionSystem,
}: {
  stripePlans: SubscriptionPlan[]
  stripeSupportPlans: SubscriptionPlan[]
  propayPlans: PropaySubscriptionPlan[]
  connectedSubscriptionSystem: ConnectedSubscriptionSystemType
}): boolean => {
  if (connectedSubscriptionSystem === 'STRIPE') {
    return stripePlans.length > 0 || stripeSupportPlans.length > 0
  } else {
    return propayPlans.length > 0
  }
}

export const isUserSubscribedToNewsletter = (
  status: AuthenticationStatusType,
  session: Session | undefined | null,
  newsletterId?: string | null,
): boolean =>
  status !== 'loading' &&
  !!newsletterId &&
  !!session?.user?.newsletterSubscriptions?.find(ns => ns.newsletterId === newsletterId)

export const getGreetingString = (date: Date) => {
  const hours = date.getHours()
  if (hours >= 1 && hours < 12) return 'Good Morning'
  else if (hours >= 12 && hours < 18) return 'Good Afternoon'
  else if (hours >= 18 && hours < 24) return 'Good Evening'
}
