'use client'

import { FacebookIcon } from '@design-system/src/components/CustomIcons/FacebookIcon'
import { PrintIcon } from '@design-system/src/components/CustomIcons/PrintIcon'
import { RedditIcon } from '@design-system/src/components/CustomIcons/RedditIcon'
import { TelegramIcon } from '@design-system/src/components/CustomIcons/TelegramIcon'
import {
  DropdownMenu,
  DropdownMenuItemType,
  DropdownMenuProps,
} from '@design-system/src/components/Dropdowns/DropdownMenu'
import { Icon } from '@design-system/src/components/Icon'
import { CopyIcon, EnvelopeClosedIcon, Share1Icon } from '@radix-ui/react-icons'
import { BlockPubArticleHeaderCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { FC, useCallback } from 'react'
import { css } from 'styled-system/css'

export const ArticleShare: FC<{
  publicationName: BlockPubArticleHeaderCustomPageData['publicationName']
  title: BlockPubArticleHeaderCustomPageData['title']
  description: BlockPubArticleHeaderCustomPageData['description']
}> = ({ publicationName, title, description }) => {
  // Copied what NY Times does in terms of user feedback when click on share item
  const onDropdownMenuItemClick = useCallback(
    (item: DropdownMenuItemType) => {
      switch (item.key) {
        case 'copy-link':
          navigator.clipboard.writeText(window.location.href || '')
          // if (isMounted.current && openSnackbar) {
          //   openSnackbar({ message: 'Copied to clipboard', type: 'neutral' })
          // } // TODO: re-implement a snackbar for
          break
        case 'email':
          window.location.href = `mailto:?subject=${window.location.origin}: ${title}&body=From ${publicationName}:%0D%0A%0D%0A${title}%0D%0A%0D%0A${description}%0D%0A%0D%0A${window.location.href}`
          break
        case 'facebook':
          // share on facebook only if on custom domain - otherwise it won't work
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`)
          break
        case 'x':
          window.open(`https://twitter.com/intent/tweet?text=${title}&url=${window.location.href}`, '_blank')
          break
        case 'telegram':
          // window.open(`https://t.me/share/url?url=${window.location.href}?smid=tel-share&text=${title}`, '_blank')
          window.open(`https://t.me/share/url?url=${window.location.href}?smid=tel-share&text=${title}`, '_blank')
          break
        case 'linkedin':
          // share on linkedin
          window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`, '_blank')
          break
        case 'whatsapp':
          // share on whatsapp
          window.open(`https://wa.me/?text=${window.location.href}`, '_blank')
          break
        case 'reddit':
          window.open(`https://reddit.com/submit?url=${window.location.href}&title=${title}`, '_blank')
          break
        case 'print':
          window.print()
          break
        default:
          console.error('Unknown dropdown menu item clicked in BlockPubArticleHeader', item)
      }
    },
    [description, publicationName, title],
  )

  return (
    <DropdownMenu
      options={dropdownMenuOptions}
      onSelectMenuItem={onDropdownMenuItemClick}
      collisionPadding={8}
      contentMenuCss={css.raw({
        '@media print': {
          display: 'none',
        },
      })}
      containerCss={css.raw({
        display: 'inline',
        '& [data-radix-popper-content-wrapper]': {
          zIndex: '[2 !important]',
        },
      })}
      triggerCss={css.raw({
        borderRadius: '$3',
      })}>
      <Icon
        reactIcon={<Share1Icon />}
        size={'20'}
        css={{
          marginLeft: '$4',
          transform: 'rotateY(180deg)',
          padding: '$2',
          cursor: 'pointer',
          borderRadius: '$round',
          borderWidth: '$1',
          borderStyle: 'solid',
          borderColor: '$gs8',
          color: '$gs11',
          _hover: {
            backgroundColor: '$gs4',
            transitionProperty: 'background-color',
            transitionDuration: '$normal',
            transitionTimingFunction: 'in-out',
          },
        }}
      />
    </DropdownMenu>
  )
}

const dropdownMenuOptions: DropdownMenuProps['options'] = [
  { label: 'Copy Link', key: 'copy-link', reactIcon: <CopyIcon /> },
  { label: 'Email', key: 'email', reactIcon: <EnvelopeClosedIcon /> },
  { label: 'Facebook', key: 'facebook', reactIcon: <FacebookIcon /> }, // TODO: add Facebook Icon
  // { label: 'X', key: 'x', reactIcon: 'twitter' },
  { label: 'Telegram', key: 'telegram', reactIcon: <TelegramIcon /> }, // TODO: add Telegram Icon
  // { label: 'LinkedIn', key: 'linkedin', reactIcon: 'linkedin' },
  // { label: 'WhatsApp', key: 'whatsapp', reactIcon: 'whatsapp' },
  { label: 'Reddit', key: 'reddit', reactIcon: <RedditIcon /> },
  { label: 'Print', key: 'print', reactIcon: <PrintIcon /> },
]
