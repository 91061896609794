import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Icon } from '@design-system/src/components/Icon'
import { Text } from '@design-system/src/components/Text'
import { CaretDownIcon } from '@radix-ui/react-icons'
import { FC, useState } from 'react'
import { css } from 'styled-system/css'

import { HeaderMenuMenuItem } from '../../../_utils/typescript-utils'
import { MainHeaderUrlMenuItem } from './MainHeaderUrlMenuItem'

export const DrawerMenuItem: FC<{
  menu: HeaderMenuMenuItem['menus'][number]
}> = ({ menu }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <CustomFlex
        align="center"
        gap="1"
        onClick={() => setIsOpen(!isOpen)}
        css={{ cursor: 'pointer', my: '$1' }}
        justify="between">
        <Text variant="body2" css={{ color: '$w4', textTransform: 'capitalize', width: '$fit' }}>
          {menu.name}
        </Text>
        <Icon
          reactIcon={<CaretDownIcon />}
          pointer={true}
          css={{
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transitionProperty: 'transform',
            transitionDuration: isOpen ? '$fast' : '0',
            transitionTimingFunction: 'ease-in-out',
          }}
        />
      </CustomFlex>
      {isOpen && (
        <ul
          className={css({
            ml: '$4',
            display: 'flex',
            flexDirection: 'column',
            gap: '$2',
          })}>
          {menu.items.map(item => {
            if (item.type === 'URL') {
              return (
                <MainHeaderUrlMenuItem
                  key={item.name}
                  item={item}
                  variant="overPrimary"
                  underlineOnHover={true}
                  wrappableText={true}
                />
              )
            }
            return null
          })}
        </ul>
      )}
    </>
  )
}
