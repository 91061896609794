'use client'

import { Box } from '@design-system/src/components/Box'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import type { BlockPubArticleBodyCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { FC } from 'react'

import { WysiwygElement } from '@models/wysiwyg-types'

import { EmailCapture } from '../../EmailCapture'

export const ArticleText: FC<{
  articleMidpoint: number
  articleContent: WysiwygElement[]
  articleType: BlockPubArticleBodyCustomPageData['articleType']
  emailCaptureConfig: BlockPubArticleBodyCustomPageData['emailCaptureConfig']
  newsletterId: BlockPubArticleBodyCustomPageData['emailCaptureConfig']['newsletterId']
}> = ({ articleContent, articleMidpoint, articleType, emailCaptureConfig, newsletterId }) => {
  // We don't want to inject the email capture form right after a heading
  let articleInjectionPoint =
    articleContent[articleMidpoint - 1]?.type === 'h3' || articleContent[articleMidpoint - 1]?.type === 'h5'
      ? articleMidpoint - 1
      : articleMidpoint

  return articleType === 'default' && articleContent ? (
    newsletterId ? (
      <CustomFlex direction="column" gap={'10'}>
        <WysiwygReader initialValue={articleContent.slice(0, articleInjectionPoint)} css={wysiwygStylesOverride} />
        <Box css={{ '@media print': { display: 'none' } }}>
          <EmailCapture {...emailCaptureConfig} variation="light" />
        </Box>
        <WysiwygReader initialValue={articleContent.slice(articleInjectionPoint)} css={wysiwygStylesOverride} />
      </CustomFlex>
    ) : (
      <WysiwygReader initialValue={articleContent} />
    )
  ) : articleContent ? (
    <WysiwygReader initialValue={articleContent} />
  ) : null
}

const wysiwygStylesOverride = {
  '& p[data-wisiwig-body1]': {
    color: '$b2',
  },
} as const
